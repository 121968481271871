<template>
  <v-container>
    <h3>{{ $t("message.title-add-category") }}</h3>
    <v-col class="pr-2 pb-2" cols="12">
      <form @submit.prevent="addCategory()">
        <v-row>
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span>{{ $t("message.label-name") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-text-field
              outlined
              v-model="name"
              :error-messages="nameErrors"
              solo
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span class="label lable-class">{{ $t("message.label-category") }}</span>
          </v-col>
          
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-select
              outlined
              v-model="category"
              :items="parentCategory"
              :error-messages="selectErrors"
              solo
              required
              @change="$v.category.$touch()"
              @blur="$v.category.$touch()"
              item-text="category_name"
              item-value="id"
            ></v-select>
          </v-col>

          <!-- Icon Category -->
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span class="label">{{ $t("message.label-image") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-file-input
              class="file-input-class"
              type="file"
              ref="fileImg"
              v-model="images"
              @change="upload"
              accept="image/*"
              outlined
              id="file-input"
              required
            >
            </v-file-input>
            <v-row v-if="isLoadingImg" class="fill-height ma-0">
              <v-progress-circular
                indeterminate
                color="blue"
              ></v-progress-circular>
            </v-row>
          </v-col>
          <template>
            <v-container class="">
              <v-row class="mb-6" no-gutters>
                <v-col>

                  <v-img
                    :src="uploadImg"
                    max-height="100px"
                    max-width="100px"
                    class=""
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-row>
        <div class="fixed-div">
          <v-row cols="12" class="row-btn">
            <v-col cols="4" md="9" xs="1" class="btn-col">
              <v-btn
                type="submit"
                class="btn-back-class"
                rounded
                color="secondary"
                dark
                @click.prevent="goBack"
              >
                {{ $t("message.btn-back") }}
              </v-btn>
            </v-col>
            <v-col cols="4" md="1" xs="1" class="btn-col">
              <v-btn
                class="btn-edit-class"
                rounded
                color="primary"
                dark
                @click="addCategory()"
              >
                <div v-if="isLoading" style="margin-right:10px">
                  <v-progress-circular
                    indeterminate
                    color="white"
                    size="11"
                    left
                  ></v-progress-circular>
                </div>
                {{ $t("message.btn-add") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </form>
    </v-col>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mdiCloseThick } from "@mdi/js";

export default {
  name: "AddCategory",
  mixins: [validationMixin],

  validations: {
    name: { required },
    category: { required },
  },

  data: () => ({
    name: "",
    category: "",
    parentCategoryId: null,
    isLoading: false,
    message: {},
    isLoadingImg: false,
    images: null,
    closeImg: mdiCloseThick,
  }),

  computed: {
    selectErrors() {
      const errors = [];
      if (!this.$v.category.$dirty) return errors;
      // !this.$v.category.required && errors.push("Category is required");
      !this.$v.category.required && 
        errors.push(this.$t("message.err-msg-select-category"))
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      // !this.$v.name.required && errors.push("Name is required.");
      !this.$v.name.required && 
        errors.push(this.$t("message.err-msg-name-category"))
      return errors;
    },
    parentCategory() {
      let mapping_resp = [
        { category_name: "# Parent Category", id: 0, is_active: true },
      ];

      let categoryParent = this.$store.state.category_module.categoryParent;
      categoryParent.forEach((element) => {
        mapping_resp.push(element);
      });
      // mapping_resp.push(this.$store.state.category_module.categoryParent)
      return mapping_resp;
    },
    uploadImg() {
      return this.$store.state.category_module.imageLink;
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchParentCategory() {
      return this.$store.dispatch("category_module/fetchParentCategory");
    },
    async addCategory() {
      this.isLoading = true;
      this.message.title = this.$t("message.title-add-category-success-message")
      this.$v.$touch();
      if (this.$v.$invalid) {
      } else {
        let payload = {
          payload: {
            category_name: this.name,
            parent: this.category,
            image_url: this.uploadImg,
            is_active: 1,
          },
          message: {
            title: this.message.title
          }
        };
        let resp = await this.$store.dispatch(
          "category_module/addCategory",
            payload
          );
        if (resp.status == 200) {
          this.isLoading = false;
          this.$router.push("/categoryMaster");
        }
      }
      this.isLoading = false;
    },
    async upload() {
      if (this.images != null) {
        await this.$store.dispatch("category_module/upload", {
          images: this.images,
          path: "categories",
        });
        // let uploadImg = await this.$store.state.category_module.imageLink;
      }else{
        await this.$store.dispatch("category_module/clearImg");
      }
    }
  },

  created() {
    this.fetchParentCategory();
  },
};
</script>

<style>
.label-class {
  text-transform: capitalize;
  color: #3f51b5;
  font-weight: bold;
}
</style>
